@font-face {
  font-family: 'Infra';
  src: local('Infra'), url(./fonts/Infra-Regular.otf) format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Infra';
  src: local('Infra'), url(./fonts/Infra-Medium.otf) format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Infra';
  src: local('Infra'), url(./fonts/Infra-SemiBold.otf) format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Infra';
  src: local('Infra'), url(./fonts/Infra-Bold.otf) format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Infra';
  src: local('Infra'), url(./fonts/Infra-ExtraBold.otf) format('woff');
  font-display: swap;
  font-weight: 800;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

body {
  font-family: "Infra", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  font-family: "Infra";
}
